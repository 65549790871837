const devBase = "https://nm.ixinangou.net"; //测试环境
const prodBase = "https://nm.ixinangou.com"; //正式环境
const suffix = "/index.php/api";

//测试环境
const dev = {
	baseUrl: devBase,
	path: `${devBase}${suffix}`
};
// 生产环境
const prod = {
	baseUrl: prodBase,
	path: `${prodBase}${suffix}`
};

export default prod;
